import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { Select, Popconfirm } from 'antd';
import { MdCreate, MdCheck, MdClose } from 'react-icons/md';

import TrashSvg from '../../../../../public/icons/trash.svg';
import SmileIcon from '../../../../common/Icon';
import {
  TIME_FORMAT,
  DATE_FORMAT,
  MINS_IN_STEP,
  MOMENT_DATE_FORMAT,
} from '../../../../../constants/formats';
import {
  onChangeDateTime,
  onChangStartDate,
  getDiffInMinutes,
  areObjectsTheSame,
} from '../../../../../utils/datesUtils';
import { useRootContext } from '../../../../../context';
import { showErrorNotification } from '../../../../../utils/errorHandlers';

import { simplifyEmployees } from '../../../Common';
import {
  minActivityDurationError,
  maxActivityDurationError,
} from '../../../../../constants/errorsMsgs';
import {
  MIN_DURATION_MINUTES,
  MAX_DURATION_MINUTES,
} from '../../../../../constants/continuum';
import MomentDatePicker from '../../../../common/MomentDatePicker';
import MomentTimePicker from '../../../../common/MomentTimePicker';

const { Option } = Select;

const taskValidSchema = Yup.object().shape({
  employeeId: Yup.number().required(),
  startDate: Yup.date().required('Choose start date'),
  startTime: Yup.date().required('Choose start time'),
  endDate: Yup.date().required('Choose end date'),
  endTime: Yup.date().required('Choose end time'),
});

export const findEmployee = (simplifiedEmployees, employeeId) => {
  const foundedEmployee = simplifiedEmployees.find(
    ({ id }) => id === employeeId
  );
  return foundedEmployee;
};

const Row = ({ isEditingAllowed, setRows, rows, employeeId, dates, index }) => {
  const {
    state: { employees, isUpdateAll },
  } = useRootContext();

  const simpleEmployees = simplifyEmployees(employees);

  const [endDate, endTime] = dates.end.split(' ');
  const [startDate, startTime] = dates.start.split(' ');

  const [prevValues, setPrevValues] = React.useState(null);
  const [isEditable, setIsEditable] = React.useState(false);
  // const [countDomInserted, setCountDomInserted] = React.useState(1);

  const deleteEmployee = id => {
    const filteredRows = rows.filter(row => row.employeeId !== id);
    setRows(filteredRows);
  };

  // const triggerDomInserted = () => setCountDomInserted(c => c + 1);

  // React.useEffect(() => {
  //   document.addEventListener('DOMNodeInserted', triggerDomInserted);
  //
  //   return () =>
  //     document.removeEventListener('DOMNodeInserted', triggerDomInserted);
  // }, []);

  // React.useEffect(() => {
  //   if (isEditable) {
  //     addTimeChangeTrigger();
  //   }
  // }, [countDomInserted, isEditable]);

  const initialValues = {
    employeeId,
    startDate: moment(startDate, MOMENT_DATE_FORMAT),
    endDate: moment(endDate, MOMENT_DATE_FORMAT),
    startTime: moment(startTime, TIME_FORMAT),
    endTime: moment(endTime, TIME_FORMAT),
  };

  const onSaveChanges = (id, values) => {
    if (getDiffInMinutes(values) < MIN_DURATION_MINUTES) {
      showErrorNotification(minActivityDurationError);
      return;
    }

    if (getDiffInMinutes(values) > MAX_DURATION_MINUTES) {
      showErrorNotification(maxActivityDurationError);
      return;
    }

    if (areObjectsTheSame(values, initialValues)) {
      setIsEditable(false);
      return;
    }

    const {
      startDate: sDate,
      endDate: eDate,
      startTime: sTime,
      endTime: eTime,
      employeeId: updEmployeeId,
    } = values;

    const updDates = {
      start: `${sDate.format(MOMENT_DATE_FORMAT)} ${sTime.format(TIME_FORMAT)}`,
      end: `${eDate.format(MOMENT_DATE_FORMAT)} ${eTime.format(TIME_FORMAT)}`,
    };
    const copyRows = [...rows.map(row => ({ ...row }))];
    const ind = copyRows.findIndex(row => row.employeeId === id);

    copyRows[ind] = {
      employeeId: updEmployeeId,
      dates: updDates,
    };

    setRows(copyRows);

    setIsEditable(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={taskValidSchema}>
      {({ isSubmitting, values, setValues, setFieldValue }) => {
        const {
          startDate: sDate,
          endDate: eDate,
          startTime: sTime,
          endTime: eTime,
          employeeId: emplId,
        } = values;

        console.log({ values });

        return (
          <Container>
            <Col>
              {index && isEditable ? (
                <SelectAnt
                  name="inspector"
                  showSearch
                  placeholder="Search"
                  notFoundContent={null}
                  defaultActiveFirstOption={false}
                  disabled={isUpdateAll ? false : !isEditable || isSubmitting}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .includes(input.toLowerCase().trim())
                  }
                >
                  {simpleEmployees.map(({ fullName, id }) => (
                    <Option key={id} value={id}>
                      {fullName}
                    </Option>
                  ))}
                </SelectAnt>
              ) : (
                findEmployee(simpleEmployees, emplId).fullName
              )}
            </Col>

            <Col>
              {isEditable ? (
                <MomentDatePicker
                  size="small"
                  name="startDate"
                  value={sDate}
                  format={DATE_FORMAT}
                  style={{ width: '100px' }}
                  onChange={curDate => {
                    return onChangStartDate(
                      curDate,
                      eDate,
                      'startDate',
                      'endDate',
                      setFieldValue,
                      sDate
                    );
                  }}
                />
              ) : (
                sDate.format(DATE_FORMAT)
              )}
            </Col>

            <Col>
              {isEditable ? (
                <MomentDatePicker
                  size="small"
                  name="endDate"
                  value={eDate}
                  format={DATE_FORMAT}
                  style={{ width: '100px' }}
                  onChange={date => {
                    return onChangeDateTime(date, 'endDate', setFieldValue);
                  }}
                />
              ) : (
                eDate.format(DATE_FORMAT)
              )}
            </Col>

            <Col>
              {isEditable ? (
                <MomentTimePicker
                  size="small"
                  name="startTime"
                  value={sTime}
                  format={TIME_FORMAT}
                  minuteStep={MINS_IN_STEP}
                  style={{ width: '80px' }}
                  onChange={time => {
                    onChangeDateTime(time, 'startTime', setFieldValue);
                  }}
                />
              ) : (
                sTime.format(TIME_FORMAT)
              )}
            </Col>

            <Col>
              {isEditable ? (
                <MomentTimePicker
                  size="small"
                  name="endTime"
                  value={eTime}
                  format={TIME_FORMAT}
                  minuteStep={MINS_IN_STEP}
                  style={{ width: '80px' }}
                  onChange={time => {
                    onChangeDateTime(time, 'endTime', setFieldValue);
                  }}
                />
              ) : (
                eTime.format(TIME_FORMAT)
              )}
            </Col>

            <Col>
              {isEditable ? (
                <>
                  <SaveChanges
                    onClick={() => {
                      onSaveChanges(employeeId, values);
                    }}
                  />

                  <DiscardChanges
                    onClick={() => {
                      setValues(prevValues);
                      setIsEditable(false);
                    }}
                  />
                </>
              ) : (
                <EditIconWrapper isEditingAllowed={isEditingAllowed}>
                  <EditIcon
                    className="edit-icon"
                    onClick={
                      isEditingAllowed
                        ? () => {
                            setIsEditable(true);
                            setPrevValues({
                              ...values,
                            });
                          }
                        : null
                    }
                  />
                </EditIconWrapper>
              )}
            </Col>

            <Col>
              {index ? (
                <Popconfirm
                  title="Are you sure you want to delete this employee?"
                  onConfirm={() => deleteEmployee(employeeId)}
                  icon={SmileIcon}
                  okText="Yes"
                  cancelText="No"
                >
                  <TrashIcon />
                </Popconfirm>
              ) : null}
            </Col>
          </Container>
        );
      }}
    </Formik>
  );
};

export default Row;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-top: none;

  .ant-calendar-picker-icon {
    display: none;
  }
`;

export const Col = styled.div`
  font-size: 17px;
  text-align: center;
  border-left: 1px solid #e9e9e9;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectAnt = styled(Select)`
  width: 200px;
`;

const EditIconWrapper = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'isEditingAllowed',
})`
  .edit-icon {
    color: ${p => (p.isEditingAllowed ? 'green' : 'grey')};
  }
`;

const EditIcon = styled(MdCreate)`
  cursor: pointer;
`;

const TrashIcon = styled(TrashSvg)`
  cursor: pointer;
`;

const SaveChanges = styled(MdCheck)`
  cursor: pointer;
  color: green;
  margin-right: 10px;
`;

const DiscardChanges = styled(MdClose)`
  cursor: pointer;
  color: red;
`;
