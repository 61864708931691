// import styled from 'styled-components';
// import * as Yup from 'yup';
// import * as Moment from 'moment';
// import { Button } from 'antd';
// import { extendMoment } from 'moment-range';
// import React, { useState, useEffect } from 'react';
// import { Formik, Form, ErrorMessage } from 'formik';
// DatePicker, TimePicker
// import { Select, Input } from 'formik-antd';

// import {
//   DATE_FORMAT,
//   TIME_FORMAT,
//   MINS_IN_STEP,
//   MOMENT_DATE_FORMAT,
// } from '../../constants/formats';
// import {
//   CancelBtn,
//   SaveBtnWithIcon,
//   DeletePlanningBtn,
// } from '../common/Button';
// import H1 from '../common/MainHeader';
// import API from '../../api';
// import {
//   createActivity,
//   updateActivity,
//   removeActivity,
//   updateUnplWorkOrders,
// } from '../../context/actions';
// import Modal from './Modal';
// import Delete from './Deleting';
// import Reccurence from './Recurrence';
// import { useRootContext } from '../../context';
// import { text, primary, basic } from '../../constants/stylesConstants';
// import { GeneralError, SpecificError } from '../common/Messages';
// import {
//   onChangeDateTime,
//   onChangStartDate,
//   areObjectsTheSame,
//   getDisabledEndDate,
// } from '../../utils/datesUtils';
// import { validateDuration } from '../../utils/validation';
// import { handleServerError } from '../../utils/errorHandlers';
// import {
//   mapServerPattern,
//   defaultInitPattern,
// } from './Recurrence/payloadMapping';
// import { simplifyLocations } from '../../utils/search';

// const moment = extendMoment(Moment);

// export const filterEmployee = (simplifiedEmployees, query) => {
//   const filterValue = query.toLowerCase().trim();
//   const filtredEmployees = simplifiedEmployees.filter(({ fullName }) =>
//     fullName
//       .split(' ')
//       .some(value => value.toLowerCase().startsWith(filterValue))
//   );
//   return filtredEmployees;
// };

export const simplifyEmployees = employeesArray => {
  if (employeesArray) {
    return employeesArray.map(
      ({ first_name: firstName, insertion, last_name: lastName, id }) => ({
        id,
        fullName: `${firstName} ${insertion} ${lastName}`,
      })
    );
  }
  return [];
};

export const addTimeChangeTrigger = () => {
  const inputTime = document.querySelector('.ant-time-picker-panel-input');

  if (inputTime)
    inputTime.oninput = e => {
      const inputValue = e.target.value.trim();
      const isNaN = Number.isNaN(+inputValue);

      if (
        !isNaN &&
        inputValue.length === 2 &&
        !inputValue.includes(':') &&
        e.inputType === 'insertText'
      ) {
        const correctTime = inputValue
          .split('')
          .reduce((newArr, curr) => {
            newArr.push(curr);

            if (newArr.length === 2) newArr.push(':');

            return newArr;
          }, [])
          .join('');

        inputTime.value = correctTime;
      }

      if (inputValue.length === 2 && e.inputType === 'deleteContentBackward') {
        inputTime.value = '';
      }

      if (!inputValue.includes(':') && isNaN) {
        inputTime.value = '';
      }
    };
};

// const filterOptions = (options, query, field) => {
//   const searchQuery = query.trim().toLowerCase();
//   if (!searchQuery) return options;
//   return options.filter(option => {
//     return option[field].toLowerCase().includes(searchQuery);
//   });
// };
//
// const { Option } = Select;
// const { TextArea } = Input;
// const taskSchema = Yup.object().shape({
//   employeeId: Yup.number().required(),
//   eventType: Yup.number().required(),
//   startDate: Yup.date().required(),
//   startTime: Yup.date().required(),
//   endDate: Yup.date().required(),
//   endTime: Yup.date().required(),
//   workOrderId: Yup.number().when('eventType', {
//     is: 2,
//     then: Yup.number().required(),
//     otherwise: Yup.number().nullable(),
//   }),
//   title: Yup.string()
//     .trim()
//     .min(3)
//     .max(100)
//     .required(),
//   description: Yup.string().trim(),
//   customerId: Yup.number().when('eventType', {
//     is: 8,
//     then: Yup.number().required('Customer is required!'),
//     otherwise: Yup.number().nullable(),
//   }),
//   workLocationId: Yup.number().when('eventType', {
//     is: 8,
//     then: Yup.number().required('Work Location is required!'),
//     otherwise: Yup.number().nullable(),
//   }),
// });
//
// const createReqObj = (
//   {
//     employeeId,
//     startDate,
//     startTime,
//     endDate,
//     endTime,
//     eventType,
//     title: updatedTitle,
//     description: updatedDescription,
//     workOrderId,
//     customerId,
//     workLocationId,
//   },
//   activityId
// ) => {
//   const reqObj = {
//     employee_id: employeeId,
//     type: eventType,
//     work_order_id: workOrderId,
//     title: updatedTitle,
//     description: updatedDescription,
//     start_date: moment(startDate).format(DATE_FORMAT),
//     start_time: startTime.format(TIME_FORMAT),
//     end_date: moment(endDate).format(DATE_FORMAT),
//     end_time: endTime.format(TIME_FORMAT),
//   };
//
//   if (activityId) reqObj.id = activityId;
//
//   if (eventType === 8) {
//     reqObj.customer_id = customerId;
//     reqObj.work_location_id = workLocationId;
//   }
//
//   return reqObj;
// };

// export default ({
//   type,
//   dates,
//   title,
//   modalType,
//   customerId,
//   activityId,
//   employeeId,
//   onHideModal,
//   description,
//   workOrderId,
//   recurrenceId,
//   workLocationId,
//   hideConflictModal,
//   updateRowConflicts,
//   hideEventType,
// }) => {
//   const {
//     state: {
//       activities,
//       employees,
//       workOrders,
//       isUpdateAll,
//       ownEmployees,
//       activitiesTypes,
//       visibleEmployees,
//       establishmentsEmployees,
//     },
//     dispatch,
//   } = useRootContext();
//
//   const [isLoading, setIsLoading] = useState(true);
//   const [isVisible, setIsVisible] = useState(false);
//   const [isEditable, setIsEditable] = useState(false);
//   const [initPattern, setInitPattern] = useState(defaultInitPattern);
//   const [generalError, setGeneralError] = useState(null);
//   const [isRecurrence, setIsRecurrence] = useState(null);
//   const [customersList, setCustomersList] = useState(null);
//   const [locationsList, setLocationsList] = useState(null);
//   const [searchEmployee, setSearchEmployee] = useState('');
//   const [searchCustomer, setSearchCustomer] = useState('');
//   const [searchLocation, setSearchLocation] = useState('');
//   const [workOrdersList, setWorkOrdersList] = useState(null);
//   const [searchWorkOrder, setSearchWorkOrder] = useState('');
//
//   const removeActivityCallback = () => {
//     removeActivity(
//       dispatch,
//       activityId,
//       updateRowConflicts ? () => updateRowConflicts(employeeId) : false,
//       activities,
//       visibleEmployees
//     );
//     onHideModal();
//     if (hideConflictModal) hideConflictModal();
//   };
//
//   let simpleEmployees = simplifyEmployees(employees);
//
//   if (!isUpdateAll && isEditable) {
//     simpleEmployees = simplifyEmployees(
//       establishmentsEmployees || ownEmployees
//     );
//   }
//
//   const getInitialValues = () => {
//     if (modalType === 'plan') {
//       return {
//         employeeId,
//         eventType: type,
//         workOrderId,
//         title,
//         description: '',
//         customerId: customerId || null,
//         workLocationId: workLocationId || null,
//       };
//     }
//
//     const [startDateStr, startTimeStr] = dates.start.split(' ');
//     const [endDateStr, endTimeStr] = dates.end.split(' ');
//     const startTime = moment(startTimeStr, TIME_FORMAT);
//     const endTime = moment(endTimeStr, TIME_FORMAT);
//     const startDate = moment(startDateStr, MOMENT_DATE_FORMAT);
//     const endDate = moment(endDateStr, MOMENT_DATE_FORMAT);
//
//     return {
//       endTime,
//       endDate,
//       startTime,
//       startDate,
//       employeeId,
//       title: title || '',
//       description: description || '',
//       workOrderId: workOrderId || null,
//       eventType: type || activitiesTypes[0].id,
//       customerId: customerId || null,
//       workLocationId: workLocationId || null,
//     };
//   };
//
//   const fetchCustomers = async () => {
//     try {
//       const customers = await API.getCustomers();
//       setCustomersList(customers);
//     } catch (e) {
//       handleServerError(e);
//     }
//   };
//
//   const fetchCustomerLocations = async id => {
//     try {
//       const data = await API.getLocationsForCustomer(id);
//       setLocationsList(simplifyLocations(data.locations));
//     } catch (e) {
//       handleServerError(e);
//     }
//   };
//
//   const onEventTypeChange = async (value, setFieldValue) => {
//     if (value === 2) setWorkOrdersList(workOrders);
//     else {
//       setWorkOrdersList(null);
//       setFieldValue('workOrderId', null);
//       setFieldValue('title', '');
//     }
//     if (value === 8) {
//       setFieldValue('customerId', null);
//       setFieldValue('workLocationId', null);
//       fetchCustomers();
//     } else {
//       setCustomersList(null);
//       setLocationsList(null);
//       setFieldValue('title', '');
//       setFieldValue('eventType', value);
//     }
//   };
//
//   const onWorkOrderChange = (value, setFieldValue) => {
//     const workOrder = workOrdersList.find(({ id }) => id === value);
//     setFieldValue('title', workOrder.work_order_number);
//     setFieldValue('workOrderId', workOrder.id);
//   };
//
//   const onCustomerChange = value => {
//     fetchCustomerLocations(value);
//   };
//
//   const filterWorkOrders = (allWorkOrders, query) => {
//     const filterValue = query.toLowerCase().trim();
//     if (!filterValue || filterValue.length <= 2) return null;
//     const filtredWorkOrder = allWorkOrders.filter(workOrder =>
//       Object.values(workOrder).some(value =>
//         String(value)
//           .toLowerCase()
//           .includes(filterValue)
//       )
//     );
//     return filtredWorkOrder.splice(0, 50);
//   };
//
//   const onSubmit = async (values, id) => {
//     if (!validateDuration(values, setGeneralError, generalError)) return;
//
//     const reqObject = createReqObj(values, id);
//
//     if (modalType === 'edit' && areObjectsTheSame(values, getInitialValues())) {
//       onHideModal();
//       return;
//     }
//
//     if (modalType === 'plan') {
//       const createdActivity = await createActivity(dispatch, reqObject);
//
//       if (createdActivity && workOrderId) {
//         updateUnplWorkOrders(dispatch, createdActivity.work_order_id);
//       }
//
//       onHideModal();
//
//       return;
//     }
//
//     if (reqObject.id) {
//       await updateActivity(
//         dispatch,
//         reqObject,
//         updateRowConflicts ? () => updateRowConflicts(employeeId) : false
//       );
//     } else {
//       await createActivity(dispatch, reqObject);
//     }
//
//     onHideModal();
//
//     if (hideConflictModal) hideConflictModal();
//   };
//
//   const getRecurrence = async () => {
//     if (recurrenceId) {
//       try {
//         const data = await API.getRecurrence(recurrenceId);
//         setInitPattern(mapServerPattern(data.recurring_activity));
//       } catch (e) {
//         handleServerError(e);
//       }
//     }
//   };
//
//   const doCheckPermission = async () => {
//     try {
//       const data = await API.checkPermission(activityId).then(result => {
//         setIsLoading(false);
//         return result;
//       });
//       setIsEditable(data);
//     } catch (e) {
//       handleServerError(e);
//     }
//   };
//
//   useEffect(() => {
//     if (customerId && workLocationId) {
//       fetchCustomers();
//       fetchCustomerLocations(customerId);
//     }
//   }, []);
//
//   useEffect(() => {
//     getRecurrence();
//   }, []);
//
//   useEffect(() => {
//     if (modalType === 'edit') {
//       doCheckPermission();
//     } else {
//       setIsLoading(false);
//       setIsEditable(true);
//     }
//   }, []);
//
//   useEffect(() => {
//     document.addEventListener('DOMNodeInserted', addTimeChangeTrigger);
//     return () =>
//       document.removeEventListener('DOMNodeInserted', addTimeChangeTrigger);
//   }, []);
//
//   if (isLoading) return <WhiteText>Loading...</WhiteText>;
//
//   return (
//     <Formik
//       initialValues={getInitialValues()}
//       validationSchema={taskSchema}
//       onSubmit={async (values, { setSubmitting }) => {
//         setSubmitting(true);
//         await onSubmit(values, activityId);
//       }}
//     >
//       {({
//         isSubmitting,
//         setFieldValue,
//         errors,
//         values,
//         validateForm,
//         setErrors,
//         setTouched,
//       }) => {
//         const {
//           startDate,
//           endDate,
//           eventType,
//           customerId: cusId,
//           workLocationId: locationId,
//         } = values;
//
//         if (
//           eventType === 8 &&
//           cusId &&
//           locationId &&
//           !customersList &&
//           !locationsList
//         )
//           return <WhiteText>Loading...</WhiteText>;
//
//         return (
//           <>
//             <Form>
//               <NewEventModal onMouseDown={e => e.stopPropagation()}>
//                 <Title>
//                   <H1 type="modal">{`${modalType} activity`}</H1>
//                 </Title>
//                 <ChoicesContent>
//                   <ChoiceWrapper>
//                     <TextMarginBottom>Employee name</TextMarginBottom>
//                     <SelectAnt
//                       placeholder="Search"
//                       showSearch
//                       name="employeeId"
//                       showArrow={false}
//                       defaultActiveFirstOption={false}
//                       filterOption={false}
//                       notFoundContent={null}
//                       onSearch={value => setSearchEmployee(value)}
//                       disabled={
//                         isUpdateAll ? false : !isEditable || isSubmitting
//                       }
//                     >
//                       {filterEmployee(simpleEmployees, searchEmployee) &&
//                         filterEmployee(simpleEmployees, searchEmployee).map(
//                           ({ fullName, id }) => (
//                             <Option key={id} value={id}>
//                               {fullName}
//                             </Option>
//                           )
//                         )}
//                     </SelectAnt>
//                   </ChoiceWrapper>
//
//                   {!hideEventType && !(modalType === 'plan') && (
//                     <ChoiceWrapper>
//                       <TextMarginBottom>Event type</TextMarginBottom>
//
//                       <SelectAnt
//                         name="eventType"
//                         onChange={value => {
//                           onEventTypeChange(value, setFieldValue);
//                         }}
//                         disabled={
//                           isUpdateAll ? false : !isEditable || isSubmitting
//                         }
//                       >
//                         {activitiesTypes.map(({ name, id }) => (
//                           <Option key={name} value={id}>
//                             {name}
//                           </Option>
//                         ))}
//                       </SelectAnt>
//                     </ChoiceWrapper>
//                   )}
//
//                   {customersList && (
//                     <ChoiceWrapper>
//                       <TextMarginBottom>Customer</TextMarginBottom>
//
//                       <SelectAnt
//                         name="customerId"
//                         showSearch
//                         onSearch={value => setSearchCustomer(value)}
//                         filterOption={false}
//                         notFoundContent={null}
//                         onChange={value => {
//                           onCustomerChange(value, setFieldValue);
//                         }}
//                         disabled={
//                           isUpdateAll ? false : !isEditable || isSubmitting
//                         }
//                       >
//                         {filterOptions(
//                           customersList,
//                           searchCustomer,
//                           'name'
//                         ).map(({ name, id }) => (
//                           <Option key={name} value={id}>
//                             {name}
//                           </Option>
//                         ))}
//                       </SelectAnt>
//
//                       <SpecificError>
//                         <ErrorMessage
//                           render={() => (
//                             <SpecificError>
//                               please, choose the customer
//                             </SpecificError>
//                           )}
//                           name="customerId"
//                         />
//                       </SpecificError>
//                     </ChoiceWrapper>
//                   )}
//
//                   {locationsList && (
//                     <ChoiceWrapper>
//                       <TextMarginBottom>Work Location</TextMarginBottom>
//
//                       <SelectAnt
//                         name="workLocationId"
//                         showSearch
//                         onSearch={value => setSearchLocation(value)}
//                         filterOption={false}
//                         notFoundContent={null}
//                         disabled={
//                           isUpdateAll ? false : !isEditable || isSubmitting
//                         }
//                       >
//                         {filterOptions(
//                           locationsList,
//                           searchLocation,
//                           'address'
//                         ).map(({ address, id }) => (
//                           <Option key={address} value={id}>
//                             {address}
//                           </Option>
//                         ))}
//                       </SelectAnt>
//
//                       <SpecificError>
//                         <ErrorMessage
//                           render={() => (
//                             <SpecificError>
//                               please, choose the work location
//                             </SpecificError>
//                           )}
//                           name="workLocationId"
//                         />
//                       </SpecificError>
//                     </ChoiceWrapper>
//                   )}
//
//                   {!workOrdersList && (
//                     <ChoiceWrapper>
//                       <TextMarginBottom>Title</TextMarginBottom>
//                       <Input
//                         disabled={
//                           isUpdateAll
//                             ? false || workOrderId
//                             : !isEditable || isSubmitting
//                         }
//                         placeholder="Title"
//                         name="title"
//                       />
//
//                       <SpecificError>
//                         <ErrorMessage name="title" />
//                       </SpecificError>
//                     </ChoiceWrapper>
//                   )}
//
//                   {workOrdersList && (
//                     <ChoiceWrapper>
//                       <TextMarginBottom>Work Order</TextMarginBottom>
//
//                       <SelectAnt
//                         placeholder="Search"
//                         showSearch
//                         name="workOrderId"
//                         showArrow={false}
//                         defaultActiveFirstOption={false}
//                         filterOption={false}
//                         notFoundContent={null}
//                         onSearch={value => setSearchWorkOrder(value)}
//                         onChange={value => {
//                           onWorkOrderChange(value, setFieldValue);
//                         }}
//                         disabled={
//                           isUpdateAll ? false : !isEditable || isSubmitting
//                         }
//                       >
//                         {filterWorkOrders(workOrdersList, searchWorkOrder) &&
//                           filterWorkOrders(workOrdersList, searchWorkOrder).map(
//                             ({ work_order_number: workOrderN, id }) => (
//                               <Option key={id} value={id}>
//                                 {workOrderN}
//                               </Option>
//                             )
//                           )}
//                       </SelectAnt>
//                       <ErrorMessage
//                         render={() => (
//                           <SpecificError>
//                             please, choose the work order
//                           </SpecificError>
//                         )}
//                         name="workOrderId"
//                       />
//                     </ChoiceWrapper>
//                   )}
//
//                   <ChoiceWrapper>
//                     <TextMarginBottom>Description</TextMarginBottom>
//                     <AntdTextArea
//                       disabled={
//                         isUpdateAll ? false : !isEditable || isSubmitting
//                       }
//                       placeholder="Description"
//                       name="description"
//                     />
//                   </ChoiceWrapper>
//
//                   <ChoiceWrapper>
//                     <TextMarginBottom>Start</TextMarginBottom>
//
//                     <PickerFlex>
//                       <FlexCol>
//                         <DatePicker
//                           name="startDate"
//                           onChange={curDate => {
//                             return onChangStartDate(
//                               curDate,
//                               endDate,
//                               'startDate',
//                               'endDate',
//                               setFieldValue,
//                               startDate
//                             );
//                           }}
//                           format={DATE_FORMAT}
//                           hideDisabledOptions
//                           disabled={
//                             isUpdateAll ? false : !isEditable || isSubmitting
//                           }
//                         />
//                         {errors.startDate && (
//                           <SpecificError>choose start date</SpecificError>
//                         )}
//                       </FlexCol>
//
//                       <FlexCol>
//                         <TimePicker
//                           name="startTime"
//                           hideDisabledOptions
//                           onChange={time => {
//                             onChangeDateTime(time, 'startTime', setFieldValue);
//                           }}
//                           format={TIME_FORMAT}
//                           minuteStep={MINS_IN_STEP}
//                           disabled={
//                             isUpdateAll ? false : !isEditable || isSubmitting
//                           }
//                         />
//                         {errors.startTime && (
//                           <SpecificError>choose start time</SpecificError>
//                         )}
//                       </FlexCol>
//                     </PickerFlex>
//                   </ChoiceWrapper>
//
//                   <ChoiceWrapper>
//                     <TextMarginBottom>End</TextMarginBottom>
//
//                     <PickerFlex>
//                       <FlexCol>
//                         <DatePicker
//                           onChange={date => {
//                             return onChangeDateTime(
//                               date,
//                               'endDate',
//                               setFieldValue
//                             );
//                           }}
//                           disabled={
//                             isUpdateAll ? false : !isEditable || isSubmitting
//                           }
//                           disabledDate={value => {
//                             return getDisabledEndDate(value, startDate);
//                           }}
//                           name="endDate"
//                           format={DATE_FORMAT}
//                         />
//                         {errors.endDate && (
//                           <SpecificError>choose end date</SpecificError>
//                         )}
//                       </FlexCol>
//                       <FlexCol>
//                         <TimePicker
//                           onChange={time => {
//                             return onChangeDateTime(
//                               time,
//                               'endTime',
//                               setFieldValue
//                             );
//                           }}
//                           name="endTime"
//                           format={TIME_FORMAT}
//                           hideDisabledOptions
//                           minuteStep={MINS_IN_STEP}
//                           disabled={
//                             isUpdateAll ? false : !isEditable || isSubmitting
//                           }
//                         />
//                         {errors.endTime && (
//                           <SpecificError>choose end time</SpecificError>
//                         )}
//                       </FlexCol>
//                     </PickerFlex>
//                   </ChoiceWrapper>
//                 </ChoicesContent>
//                 <MarginTopBottom>
//                   <GeneralError>{generalError}</GeneralError>
//                 </MarginTopBottom>
//                 <SpaceBetween>
//                   {isEditable && modalType === 'edit' && (
//                     <MarginRight>
//                       <DelBtn
//                         type="button"
//                         disabled={isSubmitting}
//                         onClick={() => setIsVisible(true)}
//                       >
//                         Delete
//                       </DelBtn>
//                     </MarginRight>
//                   )}
//                   {(((isUpdateAll || isEditable) && recurrenceId) ||
//                     modalType === 'new' ||
//                     modalType === 'plan') && (
//                     <Button
//                       onClick={() => {
//                         validateForm().then(res => {
//                           if (
//                             Object.keys(res).length ||
//                             !validateDuration(
//                               values,
//                               setGeneralError,
//                               generalError
//                             )
//                           ) {
//                             setErrors(res);
//                             setTouched(res);
//                           } else setIsRecurrence(true);
//                         });
//                       }}
//                     >
//                       {recurrenceId ? 'Edit Recurring' : 'Make Recurring'}
//                     </Button>
//                   )}
//
//                   <FlexEndActions>
//                     <MarginRight>
//                       <CancelBtn
//                         type="button"
//                         disabled={isSubmitting}
//                         onClick={onHideModal}
//                       >
//                         Cancel
//                       </CancelBtn>
//                     </MarginRight>
//
//                     {(isUpdateAll || isEditable) && (
//                       <SaveBtnWithIcon type="submit" disabled={isSubmitting}>
//                         Save
//                       </SaveBtnWithIcon>
//                     )}
//                   </FlexEndActions>
//                 </SpaceBetween>
//
//                 {isVisible && (
//                   <Modal onHideModal={() => setIsVisible(false)}>
//                     <Delete
//                       disabled={isSubmitting}
//                       resolveCallback={removeActivityCallback}
//                       rejectCallback={() => setIsVisible(false)}
//                     />
//                   </Modal>
//                 )}
//               </NewEventModal>
//             </Form>
//             {isRecurrence && (
//               <Modal onHideModal={() => setIsRecurrence(false)}>
//                 <Reccurence
//                   {...initPattern}
//                   recurrenceId={recurrenceId}
//                   activityReqObj={createReqObj(values)}
//                   hideCommon={onHideModal}
//                   hideRucurrence={() => setIsRecurrence(false)}
//                 />
//               </Modal>
//             )}
//           </>
//         );
//       }}
//     </Formik>
//   );
// };

// const NewEventModal = styled.div`
//   position: relative;
//   box-sizing: content-box;
//   width: 490px;
//   height: fit-content;
//   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.39);
//   border-radius: 10px 10px;
//   background: ${basic};
//   overflow: hidden;
//   padding-bottom: 15px;
// `;
//
// const Title = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: ${basic};
//   height: 60px;
//   background: ${primary};
// `;
//
// const WhiteText = styled.span`
//   color: white;
//   font-size: 18px;
// `;
//
// const ChoicesContent = styled.div`
//   padding: 10px 30px 0px 29px;
//
//   /* Interrupting default ant styles for Checkbox */
//
//   .ant-checkbox-wrapper {
//     display: flex;
//     align-items: center;
//     font-weight: 700;
//   }
//
//   .ant-checkbox + span {
//     padding-left: 17px;
//     color: ${text};
//   }
// `;
//
// const SelectAnt = styled(Select)`
//   width: 100%;
// `;
//
// const ChoiceWrapper = styled.div`
//   margin-bottom: 8px;
//
//   &:last-child {
//     margin-bottom: 0px;
//   }
//   /* Interrupting default ant styles for Select */
//
//   .ant-select-selection,
//   .ant-select-selection--single {
//     border: 1px solid #c1c1c1;
//   }
// `;
//
// const FlexCol = styled.div`
//   display: flex;
//   flex-direction: column;
// `;
//
// const TextMarginBottom = styled.p`
//   margin-bottom: 8px;
//   font-size: 16px;
//   font-weight: 700;
// `;
//
// const PickerFlex = styled.div`
//   display: flex;
//   justify-content: space-between;
//
//   /* Interrupting default ant styles for TimePicker */
//
//   .ant-time-picker-input {
//     color: ${text};
//     border: 1px solid #c1c1c1;
//   }
//
//   .ant-calendar-picker-input {
//     color: ${text};
//     border: 1px solid #c1c1c1;
//   }
// `;
//
// const FlexEndActions = styled.div`
//   display: flex;
//   flex-basis: 100%;
//   justify-content: flex-end;
//   align-items: center;
// `;
//
// const SpaceBetween = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0 30px;
// `;
//
// const AntdTextArea = styled(TextArea)`
//   resize: none;
//   font-size: 16px;
// `;
//
// const MarginRight = styled.div`
//   margin-right: 10px;
// `;
//
// const DelBtn = styled(DeletePlanningBtn)`
//   &:hover {
//     transition: all 0.3s ease-in-out;
//     color: ${basic};
//     background: red;
//   }
// `;
//
// const MarginTopBottom = styled.div`
//   margin: 5px 0;
// `;
