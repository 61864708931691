import React from 'react';

import styled from 'styled-components';
import moment from 'moment';
import * as Yup from 'yup';

import { Formik } from 'formik';
import { Popconfirm } from 'antd';

import { MdCreate, MdCheck, MdClose } from 'react-icons/md';

import {
  DATE_FORMAT,
  TIME_FORMAT,
  MINS_IN_STEP,
} from '../../../constants/formats';
import {
  MAX_DURATION_MINUTES,
  MIN_DURATION_MINUTES,
} from '../../../constants/continuum';
import {
  minActivityDurationError,
  maxActivityDurationError,
} from '../../../constants/errorsMsgs';

import TrashSvg from '../../../public/icons/trash.svg';
import SmileIcon from '../../common/Icon';

import { border } from '../../../constants/stylesConstants';
import {
  onChangeDateTime,
  getDiffInMinutes,
  onChangStartDate,
  areObjectsTheSame,
  getDisabledEndDate,
} from '../../../utils/datesUtils';
import { useRootContext } from '../../../context';
import { addTimeChangeTrigger } from '../Common';
import { showErrorNotification } from '../../../utils/errorHandlers';
import { updateActivity, removeActivity } from '../../../context/actions';
import MomentDatePicker from '../../common/MomentDatePicker';
import MomentTimePicker from '../../common/MomentTimePicker';

const taskValidSchema = Yup.object().shape({
  startDate: Yup.date().required('Choose start date'),
  startTime: Yup.date().required('Choose start time'),
  endDate: Yup.date().required('Choose end date'),
  endTime: Yup.date().required('Choose end time'),
});

const createReqObj = (
  { startTime, endTime, startDate, endDate },
  { id, type, title, description, orderId, employeeId }
) => {
  return {
    id,
    type,
    title,
    description,
    work_order_id: orderId,
    employee_id: employeeId,
    end_time: moment(endTime, TIME_FORMAT).format(TIME_FORMAT),
    start_time: moment(startTime, TIME_FORMAT).format(TIME_FORMAT),
    end_date: moment(endDate).format(DATE_FORMAT),
    start_date: moment(startDate).format(DATE_FORMAT),
  };
};

export default ({
  rowData,
  setEmployeesState,
  employees,
  isEditable: isAllowEdit,
}) => {
  const {
    state: { activities, visibleEmployees },
    dispatch,
  } = useRootContext();
  const [isEditable, setIsEditable] = React.useState(false);
  const [countDomInserted, setCountDomInserted] = React.useState(1);

  const initialValues = {
    startDate: moment(rowData.startDate),
    endDate: moment(rowData.endDate),
    startTime: moment(rowData.startTime, TIME_FORMAT),
    endTime: moment(rowData.endTime, TIME_FORMAT),
  };

  const triggerDomInserted = () => setCountDomInserted(c => c + 1);

  React.useEffect(() => {
    document.addEventListener('DOMNodeInserted', triggerDomInserted);

    return () =>
      document.removeEventListener('DOMNodeInserted', triggerDomInserted);
  }, []);

  React.useEffect(() => {
    if (isEditable) {
      addTimeChangeTrigger();
    }
  }, [countDomInserted, isEditable]);

  const onSaveChanges = async values => {
    if (getDiffInMinutes(values) < MIN_DURATION_MINUTES) {
      showErrorNotification(minActivityDurationError);
      return;
    }

    if (getDiffInMinutes(values) > MAX_DURATION_MINUTES) {
      showErrorNotification(maxActivityDurationError);
      return;
    }
    const reqObject = createReqObj(values, rowData);

    if (areObjectsTheSame(values, initialValues)) {
      setIsEditable(false);
      return;
    }
    const activity = await updateActivity(dispatch, reqObject);

    if (activity) {
      const employeesCopy = { ...employees };
      const selectedEmployee = employeesCopy[rowData.employeeId];
      const activityIndex = selectedEmployee.activities.findIndex(
        ({ id: activityId }) => activityId === rowData.id
      );
      selectedEmployee.activities[activityIndex] = activity;
      setEmployeesState({ ...employeesCopy });
      setIsEditable(false);
    }
  };

  const onConfirm = async () => {
    const success = await removeActivity(
      dispatch,
      rowData.id,
      false,
      activities,
      visibleEmployees
    );
    if (success) {
      const { employeeId, id: activityId } = rowData;
      const employeesCopy = { ...employees };
      const selectedEmployee = employeesCopy[employeeId];
      const filterActivities = selectedEmployee.activities.filter(
        ({ id }) => id !== activityId
      );
      if (!filterActivities.length) {
        delete employeesCopy[employeeId];
      } else {
        selectedEmployee.activities = filterActivities;
      }
      setEmployeesState(employeesCopy);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={taskValidSchema}>
      {({ values, setFieldValue }) => {
        const { startDate, endDate, startTime, endTime } = values;

        return (
          <Container>
            <Column>
              {isEditable ? (
                <MomentDatePicker
                  name="startDate"
                  size="default"
                  value={startDate}
                  format={DATE_FORMAT}
                  onChange={curDate => {
                    return onChangStartDate(
                      curDate,
                      endDate,
                      'startDate',
                      'endDate',
                      setFieldValue,
                      startDate
                    );
                  }}
                />
              ) : (
                rowData.startDate
              )}
            </Column>

            <Column>
              {isEditable ? (
                <MomentDatePicker
                  name="endDate"
                  size="default"
                  value={endDate}
                  format={DATE_FORMAT}
                  disabledDate={value => {
                    return getDisabledEndDate(value, values.startDate);

                    // moment(values.endDate).format(DATE_FORMAT)
                  }}
                  onChange={date => {
                    return onChangeDateTime(date, 'endDate', setFieldValue);
                  }}
                />
              ) : (
                rowData.endDate
              )}
            </Column>

            <Column>
              {isEditable ? (
                <MomentTimePicker
                  size="default"
                  name="startTime"
                  value={startTime}
                  format={TIME_FORMAT}
                  minuteStep={MINS_IN_STEP}
                  onChange={time => {
                    onChangeDateTime(time, 'startTime', setFieldValue);
                  }}
                />
              ) : (
                rowData.startTime
              )}
            </Column>

            <Column>
              {isEditable ? (
                <MomentTimePicker
                  name="endTime"
                  size="default"
                  value={endTime}
                  format={TIME_FORMAT}
                  minuteStep={MINS_IN_STEP}
                  onChange={time => {
                    onChangeDateTime(time, 'endTime', setFieldValue);
                  }}
                />
              ) : (
                rowData.endTime
              )}
            </Column>

            {isAllowEdit && (
              <GroupColumn>
                <Column>
                  {isEditable ? (
                    <>
                      <SaveChanges onClick={() => onSaveChanges(values)} />
                      <DiscardChanges onClick={() => setIsEditable(false)} />
                    </>
                  ) : (
                    <EditIcon onClick={() => setIsEditable(true)} />
                  )}
                </Column>

                <Column>
                  <Popconfirm
                    title="Are you sure you want to delete this activity?"
                    onConfirm={onConfirm}
                    icon={SmileIcon}
                    okText="Yes"
                    cancelText="No"
                  >
                    <TrashIcon />
                  </Popconfirm>
                </Column>
              </GroupColumn>
            )}
          </Container>
        );
      }}
    </Formik>
  );
};

const Container = styled.div`
  display: flex;
  border: ${border};
  border-top: 0px;
`;

const Column = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: ${border};
  width: 20%;
  flex-grow: 1;
  &:last-child {
    border-right: 0px;
  }
`;

const GroupColumn = styled.div`
  text-align: center;
  width: 20%;
  display: flex;

  div {
    width: 50%;
  }
`;

const EditIcon = styled(MdCreate)`
  cursor: pointer;
  color: green;
`;

const TrashIcon = styled(TrashSvg)`
  cursor: pointer;
`;

const SaveChanges = styled(MdCheck)`
  cursor: pointer;
  color: green;
  margin-right: 10px;
`;

const DiscardChanges = styled(MdClose)`
  cursor: pointer;
  color: red;
`;
