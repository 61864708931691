import { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { Select, Input } from 'formik-antd';
import { Button } from 'antd';
import * as Yup from 'yup';
import { SpecificError } from '../../../common/Messages';
import {
  FlexBeetwen,
  TwoBtnWrapper,
  Label,
} from '../../../common/styledComponents';
import Attachments from './Attachments';
import API from '../../../../api';
import { handleServerError } from '../../../../utils/errorHandlers';
import { filterOptions, restructArray } from '../../../../utils/search';
import { CenterAntSpin } from '../../../common/Spinners';
import { sectionBorder } from '../../../../constants/stylesConstants';
import { useRootContext } from '../../../../context';
import { isQERP } from '../../../../utils/hooks';

const { TextArea } = Input;
const { Option } = Select;
const fieldSize = 'large';

const contactName = 'Customer project leader';
const phoneNumberName = 'Phone number';
const emailName = 'Email address';
const locationName = 'Work location';
const purchaseNumberName = 'Purchase number';
const descriptionName = 'Work description';
const aircraft = 'Aircraft/Part';

const rawValues = {
  [contactName]: undefined,
  [phoneNumberName]: undefined,
  [emailName]: undefined,
  [locationName]: undefined,
  [purchaseNumberName]: undefined,
  [descriptionName]: undefined,
  [aircraft]: undefined,
};

const createRequestObj = (values, filesId, isQerpUrl) => {
  const reqObj = {
    project_id: values.project_id,
    acquaintance_id: values.customerId,
    acquaintance_project_leader_id: values[contactName],
    acquaintance_project_leader_phone_number: values[phoneNumberName] || null,
    acquaintance_project_leader_email: values[emailName] || null,
    purchase_number: values[purchaseNumberName],
    work_location_id: values[locationName],
    work_description: values[descriptionName],
    purchase_order_document_id: null,
    uploaded_files_ids: filesId,
  };
  if (!isQerpUrl) reqObj.aircraft = values[aircraft];

  return reqObj;
};

const validationSchema = isQerpUrl =>
  Yup.object().shape({
    [contactName]: Yup.number().required(),
    [phoneNumberName]: Yup.string()
      .trim()
      .min(4),
    [emailName]: Yup.string()
      .trim()
      .email(),
    [purchaseNumberName]: Yup.string()
      .trim()
      .required(),
    [locationName]: Yup.number().required(),
    [descriptionName]: Yup.string()
      .trim()
      .required(),
    [aircraft]: isQerpUrl ? Yup.string() : Yup.string(),
  });

export default ({
  exit,
  prev,
  next,
  finish,
  setStep1Data,
  projectId,
  locations,
  customerContacts,
  preparePropsForWorkOrder,
  projectData: {
    description,
    purchaseNumber,
    locationId,
    contactId,
    phone,
    email,
  },
}) => {
  const initialValues = {
    project_id: projectId,
    ...rawValues,
    [contactName]: contactId || undefined,
    [phoneNumberName]: phone || undefined,
    [emailName]: email || undefined,
    [locationName]: locationId || undefined,
    [purchaseNumberName]: undefined,
    [descriptionName]: description || undefined,
  };

  const isQerpUrl = isQERP(window.location.host);

  const buttonText = isQerpUrl ? 'Next' : 'Create Work Order';

  const {
    state: { isFileLoading, attachedFiles },
  } = useRootContext();
  const filesId = attachedFiles.map(file => file.fileId);
  const [query, setQuery] = useState('');
  const handleContactChange = (value, formikValues, formikSetValues) => {
    const found = customerContacts.find(({ id }) => id === value);
    if (query) setQuery('');
    if (found) {
      formikSetValues({
        ...formikValues,
        [contactName]: value,
        [phoneNumberName]: found.phone_numbers[0],
        [emailName]: found.emails[0],
      });
    }
  };

  const handleSubmit = async values => {
    try {
      const data = await API.postWorkOrder(values);
      return data;
    } catch (e) {
      handleServerError(e);
      return null;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(isQerpUrl)}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        if (!isQerpUrl) {
          const data = await handleSubmit(
            createRequestObj(values, filesId, isQerpUrl)
          );
          if (data) finish(data.work_order_id, data.work_order_title);
          setSubmitting(false);
        } else {
          const step1Data = createRequestObj(values, filesId, isQerpUrl);
          const preparePropsObject = {
            id: step1Data.project_id,
            email: values[emailName],
            phone: values[phoneNumberName],
            contactId: values[contactName],
            locationId: values[locationName],
            purchaseNumber: values[purchaseNumberName],
            description: values[descriptionName],
          };
          if (!isQerpUrl) preparePropsObject.aircraft = values[aircraft];
          preparePropsForWorkOrder(preparePropsObject);
          setStep1Data(step1Data);
          setSubmitting(false);
          next();
        }
      }}
    >
      {({ isSubmitting, values, setValues, setFieldValue }) => {
        return (
          <Form>
            <FlexItems>
              <Container>
                <Label htmlFor={contactName}>{contactName}</Label>
                <WideSelect
                  disabled={isSubmitting || isFileLoading}
                  placeholder={contactName}
                  showSearch
                  name={contactName}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  notFoundContent={null}
                  size={fieldSize}
                  onSearch={value => setQuery(value)}
                  onChange={value => {
                    handleContactChange(value, values, setValues);
                  }}
                >
                  {filterOptions(customerContacts, query, 'full_name').map(
                    ({ full_name: name, id }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    )
                  )}
                </WideSelect>
                <SpecificError>
                  <ErrorMessage name={contactName} />
                </SpecificError>
                <Label htmlFor={phoneNumberName}>{phoneNumberName}</Label>
                <Input
                  disabled={isSubmitting || isFileLoading}
                  size={fieldSize}
                  placeholder={phoneNumberName}
                  name={phoneNumberName}
                />
                <SpecificError>
                  <ErrorMessage name={phoneNumberName} />
                </SpecificError>
                <Label htmlFor={emailName}>{emailName}</Label>
                <Input
                  disabled={isSubmitting || isFileLoading}
                  size={fieldSize}
                  placeholder={emailName}
                  name={emailName}
                />
                <SpecificError>
                  <ErrorMessage name={emailName} />
                </SpecificError>
                <Label htmlFor={locationName}>{locationName}</Label>
                {locations && locations.length ? (
                  <WideSelect
                    disabled={isSubmitting || isFileLoading}
                    placeholder={locationName}
                    showSearch
                    name={locationName}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    notFoundContent={null}
                    size={fieldSize}
                    onSearch={value => setQuery(value)}
                    onChange={() => setQuery('')}
                  >
                    {filterOptions(
                      restructArray(locations, values[locationName], 'id'),
                      query,
                      'address'
                    )
                      .slice(0, 50)
                      .map(({ address, id }) => (
                        <Option key={id} value={id}>
                          {address}
                        </Option>
                      ))}
                  </WideSelect>
                ) : (
                  <CenterAntSpin />
                )}
                <SpecificError>
                  <ErrorMessage name={locationName} />
                </SpecificError>
                <Label htmlFor={purchaseNumberName}>{purchaseNumberName}</Label>
                <FlexItem>
                  <Input
                    disabled={isSubmitting || isFileLoading}
                    size={fieldSize}
                    placeholder={purchaseNumberName}
                    name={purchaseNumberName}
                  />
                  {purchaseNumber && (
                    <StyledButton
                      disabled={isSubmitting || isFileLoading}
                      onClick={() => {
                        setFieldValue(purchaseNumberName, purchaseNumber);
                      }}
                    >
                      Take Over
                    </StyledButton>
                  )}
                </FlexItem>

                <SpecificError>
                  <ErrorMessage name={purchaseNumberName} />
                </SpecificError>
                <Label htmlFor={descriptionName}>{descriptionName}</Label>
                <AntdTextArea
                  disabled={isSubmitting || isFileLoading}
                  autoSize={{ minRows: 3, maxRows: 4 }}
                  placeholder={descriptionName}
                  name={descriptionName}
                />
                <SpecificError>
                  <ErrorMessage name={descriptionName} />
                </SpecificError>

                {!isQerpUrl && (
                  <div>
                    <Label htmlFor={aircraft}>{aircraft}</Label>
                    <Input
                      disabled={isSubmitting || isFileLoading}
                      size={fieldSize}
                      placeholder={aircraft}
                      name={aircraft}
                    />
                    <SpecificError>
                      <ErrorMessage name={aircraft} />
                    </SpecificError>
                  </div>
                )}
              </Container>

              <GrowWrapper>
                <Attachments />
                <StyledFlexBeetwen>
                  <Button
                    type="dashed"
                    disabled={isSubmitting || isFileLoading}
                    onClick={exit}
                  >
                    Exit
                  </Button>
                  <TwoBtnWrapper>
                    <Button
                      disabled={isSubmitting || isFileLoading}
                      onClick={prev}
                    >
                      Back
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isSubmitting || isFileLoading}
                    >
                      {buttonText}
                    </Button>
                  </TwoBtnWrapper>
                </StyledFlexBeetwen>
              </GrowWrapper>
            </FlexItems>
          </Form>
        );
      }}
    </Formik>
  );
};

const FlexItems = styled.div`
  display: flex;
`;

const Container = styled.div`
  padding: 20px;
  flex-shrink: 0;
  flex-basis: 300px;
  border-right: ${sectionBorder};
`;

const GrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 300px;
`;

const WideSelect = styled(Select)`
  width: 100%;
`;

const AntdTextArea = styled(TextArea)`
  resize: none;
  font-size: 16px;
`;

const FlexItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledFlexBeetwen = styled(FlexBeetwen)`
  padding: 20px;
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 5px;
`;
